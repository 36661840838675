<template>
    <div class="vx-col md:w-1/1 w-full">
        <vx-card title="BILLORDER Report" style="z-index:10">
            <div class="vx-row mb-6" style="width:50%">
                <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left" @click="$router.go(-1)">Back</vs-button>
            </div>
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Operating Unit / Territory</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                        class="selectExample"
                        v-model="operatingUnit"
                        :options="optionOUs"
                        :multiple="true"
                        @input="onChange"
                        @select="onSelect"
                        :allow-empty="false"
                        :group-select="false"
                        :max-height="120"
                        :limit="5"
                        placeholder="Type to search"
                        track-by="ID"
                        label="Name"
                    >

                        <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                                <span class="option__title">{{ props.option.Name }}</span>
                            </span>
                        </template>
                            
                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">{{ props.option.SelectName }}</span>
                            </div>
                        </template>

                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Distribution Channel</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                        class="selectExample"
                        v-model="customerCategory"
                        :options="optionCustomerCategory"
                        :multiple="true"
                        :allow-empty="false"
                        :group-select="false"
                        :max-height="120"
                        :limit="3"
                        placeholder="Type to search"
                        track-by="ID"
                        label="name"
                    >

                        <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span>
                            </div>
                        </template>

                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Sales</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                        class="selectExample"
                        v-model="salesPersonal"
                        :options="optionSalesPersonal"
                        :multiple="true"
                        :allow-empty="false"
                        :group-select="false"
                        :max-height="120"
                        :limit="3"
                        placeholder="Type to search"
                        track-by="id"
                        label="name"
                    >

                        <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span>
                            </div>
                        </template>

                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Division / Principal</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                        class="selectExample"
                        v-model="supplier"
                        :options="optionSupplier"
                        :multiple="true"
                        :allow-empty="false"
                        :group-select="false"
                        :max-height="120"
                        :limit="3"
                        placeholder="Type to search"
                        track-by="ID"
                        label="Name"
                    >

                        <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                                <span class="option__title">{{ props.option.Name }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">{{ props.option.Name }}</span>
                            </div>
                        </template>

                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>SO Date</span>&nbsp;
                    <feather-icon title="Reset" icon="RefreshCwIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="resetDateFilter('date')"/>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <date-range-picker
                        style="min-height: 40px"
                        class="w-full"
                        ref="picker"
                        opens="center"
                        :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                        :singleDatePicker="false"
                        :timePicker="false"
                        :timePicker24Hour="false"
                        :showWeekNumbers="false"
                        :showDropdowns="false"
                        :autoApply="true"
                        v-model="filterDate"
                        :linkedCalendars="true"
                    >
                        <template v-slot:input="picker" style="min-width: 350px">
                            {{ datePickerDateFormat(picker.startDate) }} -
                            {{ datePickerDateFormat(picker.endDate) }}
                        </template>
                    </date-range-picker>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Posting Date</span>&nbsp;
                    <feather-icon title="Reset" icon="RefreshCwIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="resetDateFilter('posting')"/>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <date-range-picker
                        style="min-height: 40px"
                        class="w-full"
                        ref="picker"
                        opens="center"
                        :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                        :singleDatePicker="false"
                        :timePicker="false"
                        :timePicker24Hour="false"
                        :showWeekNumbers="false"
                        :showDropdowns="false"
                        :autoApply="true"
                        v-model="filterPostingDate"
                        :linkedCalendars="true"
                    >
                        <template v-slot:input="picker" style="min-width: 350px">
                            {{ datePickerDateFormat(picker.startDate) }} -
                            {{ datePickerDateFormat(picker.endDate) }}
                        </template>
                    </date-range-picker>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Billing Date</span>&nbsp;
                    <feather-icon title="Reset" icon="RefreshCwIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="resetDateFilter('billing')"/>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <date-range-picker
                        style="min-height: 40px"
                        class="w-full"
                        ref="picker"
                        opens="center"
                        :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                        :singleDatePicker="false"
                        :timePicker="false"
                        :timePicker24Hour="false"
                        :showWeekNumbers="false"
                        :showDropdowns="false"
                        :autoApply="true"
                        v-model="filterBillingDate"
                        :linkedCalendars="true"
                    >
                        <template v-slot:input="picker" style="min-width: 350px">
                            {{ datePickerDateFormat(picker.startDate) }} -
                            {{ datePickerDateFormat(picker.endDate) }}
                        </template>
                    </date-range-picker>
                </div>
            </div>
            
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-button class="ml-4 mt-2 vx-col sm:w-2/4" color="primary" icon-pack="feather" icon="icon-prin" @click="handleDrawTable()">Get Data</vs-button>
                </div>
            </div>
        </vx-card>
        <vx-card title="" style="z-index:9">
            <vs-tabs :color="colorx" ref="tabs">
                <vs-tab @click="changeTab(1)" label="BILLORDER Report">
                    <div class="con-tab-ejemplo">
                        <br>
                        <monitoring :operatingUnit="operatingUnit" :territories="TerritoryCodes" :customerCategory="customerCategory" :salesPersonal="salesPersonal"  
                        v-on:selectSalesOrder="updateSelectedSalesOrder" v-on:redirectTab="redirectTab" :filterDate="filterDate" 
                        :filterPostingDate="filterPostingDate" :filterBillingDate="filterBillingDate" :draw="draw" :supplier="supplier"></monitoring>
                    </div>
                </vs-tab>
                <vs-tab label="Export">
                    <div class="tab-text">
                        <data-table-export></data-table-export>
                    </div>
                </vs-tab>
            </vs-tabs>
        </vx-card>
    </div>
</template>

<script>
import monitoring from "./monitoring/index.vue";
import DataTableExport from "./monitoring/DataTableExport.vue";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
    components: {
        monitoring,
        DateRangePicker,
        DataTableExport
    },
    mounted() {
        this.getOptionOU();
        this.getOptionCustomerCategory();
        this.getOptionSalesPersonal();
        this.getOptionSupplier();

        const today = new Date();
        this.filterDate.startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        this.filterDate.endDate = new Date(today.getFullYear(), today.getMonth()+1, 0);
        this.filterPostingDate.startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        this.filterPostingDate.endDate = new Date(today.getFullYear(), today.getMonth()+1, 0);
        this.filterBillingDate.startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        this.filterBillingDate.endDate = new Date(today.getFullYear(), today.getMonth()+1, 0);
    },
    data: () => ({
        tabColor:['success', 'primary', 'warning', 'info', 'success', 'rgb(62, 201, 214)'],
        tabIndex: 0,
        buttonVisible: false,
        draw: 0,
        salesChannel: [],
        customerCategory: [],
        optionCustomerCategory:[{}],
        salesPersonal: [],
        optionSalesPersonal:[{}],
        supplier: [],
        optionSupplier:[{}],
        colorx: "success",
        selectedSO: {},
        TerritoryCodes: [{}], 
        operatingUnit: {
            OperatingUnitTerritory: {
                Territory: {
                    code: ""
                }
            }
        },
        optionOUs: [{
            Code: "-",
            ID: "0",
            OperatingUnitTerritory: {
                Code: "All",
                Territory: {
                    ID: 0, code: "All", name: "All"
                }
            }
        }],
        optionOU: [{
            OperatingUnitTerritory: {
                Territory: {
                    code: ""
                }
            }
        }],
        
        filterDate: {
            startDate: null,
            endDate: null,
        },
        filterPostingDate: {
            startDate: null,
            endDate: null,
        },
        filterBillingDate: {
            startDate: null,
            endDate: null,
        },
    }),
    watch: {
    },
    methods: {
        handleDrawTable(){
             this.draw++;
             console.log("this.draw", this.draw)
        },
        changeTab(index) {
            // console.log("before", this.tabIndex);
            // console.log("after", index);
            this.tabIndex = index;
            this.colorx = this.tabColor[index];
        },
        datePickerDateFormat(date) {
            let a = null;
            if (date != null) {
                a = moment(date).format("dddd, MMMM Do YYYY");
            }
            return a;
        },
        resetDateFilter(name) {
            if(name=="date") {
                this.filterDate.startDate = null;
                this.filterDate.endDate = null;
            } else if(name=="posting") {
                this.filterPostingDate.startDate = null;
                this.filterPostingDate.endDate = null;
            } else if(name=="billing") {
                this.filterBillingDate.startDate = null;
                this.filterBillingDate.endDate = null;
            }
        },
        getOptionOU() {
            this.$http.get("/api/v1/master/operating-unit-territory").then(resp => {
                this.optionOU = resp.data.records;
                let datas = resp.data.records
                this.optionOUs = []
                this.optionOUs.push({
                    CodeOu: "AllOu",
                    CodeOuTerr: "AllOuTerr",
                    CodeTerr: "AllCode",
                    ID: "zero",
                    IDTerr: "zeroTerr",
                    SelectName: "All OU/Territory",
                    Name: "All",
                })
                for (var idx = 0; idx < resp.data.records.length; idx++) {
                    this.optionOUs.push({
                        CodeOu: datas[idx].Territory.code,
                        CodeOuTerr: datas[idx].Territory.code,
                        CodeTerr: datas[idx].Territory.code,
                        ID: datas[idx].Territory.ID,
                        IDTerr: datas[idx].Territory.ID, code: datas[idx].Territory.Code,
                        SelectName: " (" + datas[idx].Territory.code + ") " + datas[idx].Territory.name,
                        Name: " (" + datas[idx].Territory.code + ") " + datas[idx].Territory.name
                    })
                }
                this.operatingUnit = this.optionOUs[0];
            });
        },
        getOptionCustomerCategory() {
            this.$http.get("/api/v1/master/customer-category-all").then(
                resp => {
                    if(resp.code == 200){
                        this.optionCustomerCategory = []
                        this.optionCustomerCategory.push({ID:0, name:'All'})
                        for(var customerCategory = 0;customerCategory<resp.data.length;customerCategory++){
                            this.optionCustomerCategory.push(resp.data[customerCategory])
                        }
                        this.customerCategory = [this.optionCustomerCategory[0]];
                    }else{
                        // this.$vs.loading.close();
                        this.$router.push("/");
                    }
                }
            );
        },
        getOptionSalesPersonal() {
            this.$http.get("/api/v1/sales-order/sales-personal").then(
                resp => {
                    if(resp.code == 200){
                        this.optionSalesPersonal = []
                        this.optionSalesPersonal.push({id: 0, name:'All'})
                        let data = resp.data.SalesPersonalSO
                        for(var idx = 0;idx<data.length;idx++){
                            this.optionSalesPersonal.push(data[idx])
                        }
                        this.salesPersonal = [this.optionSalesPersonal[0]];
                    }else{
                        // this.$vs.loading.close();
                        // this.$router.push("/");
                    }
                }
            );
        },
        getOptionSupplier() {
            this.$http.get("/api/v1/master/suppliers").then(
                resp => {
                    if(resp.code == 200){
                        this.optionSupplier = []
                        this.optionSupplier.push({ID:0, Name:'All'})
                        for(var idx = 0;idx<resp.data.records.length;idx++){
                            this.optionSupplier.push(resp.data.records[idx])
                        }
                        this.supplier = [this.optionSupplier[0]];
                    }else{
                        // this.$vs.loading.close();
                        this.$router.push("/");
                    }
                }
            );
        },
        updateSelectedSalesOrder(salesOrder) {
            this.selectedSO = salesOrder;
            this.changeTab(1);
            // console.log("selected", this.selectedSO);
        },
        redirectTab(index) {
            this.$refs.tabs.activeChild(index);
        },
        onChange(value) {
            let keys = Object.keys(value)
            let data = []
            keys.forEach(function (val, i, vals) {
                // this.TerritoryCodes = [1, 2, 3, 4]
                if (value[i].IDTerr != "zeroTerr" && value[i].IDTerr != undefined) {
                    data.push({ ID: value[i].IDTerr, Code: value[i].CodeTerr })
                } else {
                    data = [{
                        ID: 0, Code: "All"
                    }]
                }
            });
            this.TerritoryCodes = data
        },
        onSelect(val, i) {

            if (val.ID == "zero") {
                this.getOptionOU
            }
            console.log("OnSelect")
            console.log(val.ID)
            console.log(i)
        },
    }
};
</script>

<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
</style>